import React from 'react';
import { FormControlLabel, Checkbox, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'class-names';

import { QUESTION_COUNTER_WIDTH } from '../../_constants/styleConstants';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingLeft: QUESTION_COUNTER_WIDTH,
    margin: `${theme.spacing(1)}px 0`
  },
  wrapperRadioMargin: {
    margin: `${theme.spacing(1 / 2)}px 0`
  },
  formCtrlLabel: {
    marginRight: 0,
    '& .MuiTypography-body1': {
      lineHeight: '1.5',
      fontSize: 16
    }
  },
  checkbox: {
    padding: `${theme.spacing(1) / 4}px`,
    marginRight: theme.spacing(1)
  }
}));

export const Answer = ({ text, onChange, checked, value, disabled, children, checkbox = false }) => {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.wrapper, {
        [classes.wrapperRadioMargin]: !checkbox
      })}
    >
      <FormControlLabel
        className={classes.formCtrlLabel}
        disabled={disabled}
        control={
          checkbox ? (
            <Checkbox
              className={classes.checkbox}
              onChange={onChange}
              value={value}
              checked={checked}
              color="primary"
            />
          ) : (
            <Radio color="primary" className={classes.checkbox} />
          )
        }
        {...(!checkbox && { value: value })}
        label={text}
      />
      {children}
    </div>
  );
};
