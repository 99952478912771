import { isNTrue } from '../helpers';
import { QUESTION_RESULT } from '../../_constants/commonConstants';

export class BillOfExchangeEvalStrategy {
  constructor(state) {
    this._answers = state.questionsAndAnswers;
    this._age = state.age;
  }

  calculateKnowledge() {
    const answers = this._answers;
    let result = QUESTION_RESULT.NONE;

    if (answers[4][3]) {
      if (isNTrue([answers[8][2], answers[9][2], answers[10][3]], 1)) {
        result = QUESTION_RESULT.BASIC;
      }
      if (isNTrue([answers[8][2], answers[9][2], answers[10][3]], 2)) {
        result = QUESTION_RESULT.ADVANCED;
      } else if (isNTrue([answers[8][2], answers[9][2], answers[10][3]], 3)) {
        result = QUESTION_RESULT.EXPERT;
      }
    }

    return result;
  }

  calculateExperience() {
    const answers = this._answers;
    let result = QUESTION_RESULT.NONE;

    if (answers[14][2][2] && !answers[15][2][1]) {
      result = QUESTION_RESULT.BASIC;
    } else if (
      (answers[14][2][3] && !answers[15][2][1]) ||
      (answers[14][2][4] && (answers[15][2][2] || answers[15][2][3]))
    ) {
      result = QUESTION_RESULT.ADVANCED;
    } else if (answers[14][2][4] && answers[15][2][4]) {
      result = QUESTION_RESULT.EXPERT;
    }

    return result;
  }

  calculatePositiveBy2a(knowledge, experience) {
    return knowledge !== QUESTION_RESULT.NONE && experience !== QUESTION_RESULT.NONE;
  }

  calculatePositiveBy2b(knowledge, experience) {
    return (
      experience === QUESTION_RESULT.NONE &&
      (knowledge === QUESTION_RESULT.EXPERT || knowledge === QUESTION_RESULT.ADVANCED)
    );
  }

  calculatePositiveBy4() {
    return this._answers[26][1] && this._age <= 80;
  }

  calculatePositiveBy5() {
    return this._answers[27][1];
  }

  calculatePositiveBy6() {
    return !this._answers[28][3] && !this._answers[28][2];
  }

  calculatePositiveBy7() {
    return this._answers[23][1];
  }

  evaluate() {
    const knowledgeResult = this.calculateKnowledge();
    const experienceResult = this.calculateExperience();
    const isPositiveBy2a = this.calculatePositiveBy2a(knowledgeResult, experienceResult);
    const isPositiveBy2b = this.calculatePositiveBy2b(knowledgeResult, experienceResult);
    const isPositiveBy2 = isPositiveBy2a || isPositiveBy2b;
    const isPositiveBy4 = this.calculatePositiveBy4();
    const isPositiveBy5 = this.calculatePositiveBy5();
    const isPositiveBy6 = this.calculatePositiveBy6();
    const isPositiveBy7 = this.calculatePositiveBy7();

    const isTotallyPositive = isPositiveBy2 && isPositiveBy4 && isPositiveBy5 && isPositiveBy6 && isPositiveBy7;

    return [
      {
        id: 'knowledgeBillsOfExchange',
        label: 'Informácie o znalostiach klienta (zmenky)',
        isIndented: true,
        result: knowledgeResult
      },
      {
        id: 'experienceBillsOfExchange',
        label: 'Informácie o skúsenostiach klienta (zmenky)',
        isIndented: true,
        result: experienceResult
      },
      {
        id: 'positiveTotal',
        label: 'Klient je pre cieľový trh zmenky',
        result: isTotallyPositive
      },
      {
        id: 'positive1',
        label: 'Klient je pre cieľový trh zmenky podľa bodu 1',
        result: true
      },
      {
        id: 'positive2a',
        label: 'Klient je pre cieľový trh zmenky podľa bodu 2a',
        result: isPositiveBy2a
      },
      {
        id: 'positive2b',
        label: 'Klient je pre cieľový trh zmenky podľa bodu 2b',
        result: isPositiveBy2b
      },
      {
        id: 'positive2',
        label: 'Klient je pre cieľový trh zmenky podľa bodu 2',
        result: isPositiveBy2
      },
      {
        id: 'positive3',
        label: 'Klient je pre cieľový trh zmenky podľa bodu 3',
        result: true
      },
      {
        id: 'positive4',
        label: 'Klient je pre cieľový trh zmenky podľa bodu 4',
        result: isPositiveBy4
      },
      {
        id: 'positive5',
        label: 'Klient je pre cieľový trh zmenky podľa bodu 5',
        result: isPositiveBy5
      },
      {
        id: 'positive6',
        label: 'Klient je pre cieľový trh zmenky podľa bodu 6',
        result: isPositiveBy6
      },
      {
        id: 'positive7',
        label: 'Klient je pre cieľový trh zmenky podľa bodu 7',
        result: isPositiveBy7
      }
    ];
  }
}
