import { isNTrue } from '../helpers';
import { QUESTION_RESULT } from '../../_constants/commonConstants';

export class ShareEvalStrategy {
  constructor(state) {
    this._answers = state.questionsAndAnswers;
    this._age = state.age;
  }

  calculateKnowledge() {
    const answers = this._answers;
    let result = QUESTION_RESULT.NONE;

    if (answers[4][4]) {
      if (isNTrue([answers[11][3], answers[12][3], answers[13][2]], 1)) {
        result = QUESTION_RESULT.BASIC;
      }
      if (isNTrue([answers[11][3], answers[12][3], answers[13][2]], 2)) {
        result = QUESTION_RESULT.ADVANCED;
      } else if (isNTrue([answers[11][3], answers[12][3], answers[13][2]], 3)) {
        result = QUESTION_RESULT.EXPERT;
      }
    }

    return result;
  }

  calculateExperience() {
    const answers = this._answers;
    let result = QUESTION_RESULT.NONE;

    if (answers[14][3][2] && !answers[15][3][1]) {
      result = QUESTION_RESULT.BASIC;
    } else if (
      (answers[14][3][3] && !answers[15][3][1]) ||
      (answers[14][3][4] && (answers[15][3][2] || answers[15][3][3]))
    ) {
      result = QUESTION_RESULT.ADVANCED;
    } else if (answers[14][3][4] && answers[15][3][4]) {
      result = QUESTION_RESULT.EXPERT;
    }

    return result;
  }

  calculatePositiveBy2(knowledge, experience) {
    return (
      (knowledge === QUESTION_RESULT.ADVANCED || knowledge === QUESTION_RESULT.EXPERT) &&
      (experience === QUESTION_RESULT.ADVANCED || experience === QUESTION_RESULT.EXPERT)
    );
  }

  calculatePositiveby3() {
    const answers = this._answers;

    return answers[25][4] && answers[26][3];
  }

  calculatePositiveBy4() {
    return this._answers[26][3] && this._age <= 70;
  }

  calculatePositiveBy5() {
    return this._answers[27][2];
  }

  calculatePositiveBy6() {
    return this._answers[28][3];
  }

  calculatePositiveBy7() {
    return this._answers[23][1];
  }

  evaluate() {
    const knowledgeResult = this.calculateKnowledge();
    const experienceResult = this.calculateExperience();
    const isPositiveBy2 = this.calculatePositiveBy2(knowledgeResult, experienceResult);
    const isPositiveBy3 = this.calculatePositiveby3();
    const isPositiveBy4 = this.calculatePositiveBy4();
    const isPositiveBy5 = this.calculatePositiveBy5();
    const isPositiveBy6 = this.calculatePositiveBy6();
    const isPositiveBy7 = this.calculatePositiveBy7();

    const isTotallyPositive = isPositiveBy2 && isPositiveBy4 && isPositiveBy5 && isPositiveBy6 && isPositiveBy7;

    return [
      {
        id: 'knowledgeBillsOfExchange',
        label: 'Informácie o znalostiach klienta (akcie)',
        isIndented: true,
        result: knowledgeResult
      },
      {
        id: 'experienceBillsOfExchange',
        label: 'Informácie o skúsenostiach klienta (akcie)',
        isIndented: true,
        result: experienceResult
      },
      {
        id: 'positiveTotal',
        label: 'Klient je pre cieľový trh akcie',
        result: isTotallyPositive
      },
      {
        id: 'positive1',
        label: 'Klient je pre cieľový trh akcie podľa bodu 1',
        result: true
      },
      {
        id: 'positive2',
        label: 'Klient je pre cieľový trh akcie podľa bodu 2',
        result: isPositiveBy2
      },
      {
        id: 'positive3',
        label: 'Klient je pre cieľový trh akcie podľa bodu 3',
        result: isPositiveBy3
      },
      {
        id: 'positive4',
        label: 'Klient je pre cieľový trh akcie podľa bodu 4',
        result: isPositiveBy4
      },
      {
        id: 'positive5',
        label: 'Klient je pre cieľový trh akcie podľa bodu 5',
        result: isPositiveBy5
      },
      {
        id: 'positive6',
        label: 'Klient je pre cieľový trh akcie podľa bodu 6',
        result: isPositiveBy6
      },
      {
        id: 'positive7',
        label: 'Klient je pre cieľový trh akcie podľa bodu 7',
        result: isPositiveBy7
      }
    ];
  }
}
