import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'class-names';

import { QUESTION_COUNTER_WIDTH } from '../../_constants/styleConstants';

const useStyles = makeStyles({
  questionLabel: {
    display: 'flex',
    fontSize: 18,
    lineHeight: 1.5,
    fontWeight: 500
  },
  count: {
    width: QUESTION_COUNTER_WIDTH
  },
  text: {
    flex: '1 1 100%'
  },
  disabled: {
    filter: 'opacity(0.38)'
  }
});

export const Question = ({ text, number, wrapperClassName, className, children, hideNumber, disabled }) => {
  const classes = useStyles();

  return (
    <div className={cx(wrapperClassName, { [classes.disabled]: disabled })}>
      <div id={`question${number}`} className={cx(classes.questionLabel, className)}>
        {!hideNumber && <div className={classes.count}>{number}.</div>}
        <div className={classes.text}>{text}</div>
      </div>
      {children}
    </div>
  );
};
