export class GeneralStrategy {
  constructor(state) {
    this._answers = state.questionsAndAnswers;
    this._age = state.age;
    this._investment = state.investment;
    this._investmentPercentage = Number(state.investmentPercentageFromAssets);
  }

  calculateRiskApproach() {
    const answers = this._answers;
    let result = '';

    if (
      answers[25][1] ||
      answers[25][2] ||
      (answers[25][3] && answers[26][1]) ||
      (answers[25][4] && answers[26][1])
    ) {
      result = 'Konzervatívny';
    } else if ((answers[25][3] && (answers[26][2] || answers[26][3])) || (answers[25][4] && answers[26][2])) {
      result = 'Vyvážený';
    } else if (answers[25][4] && answers[26][3]) {
      result = 'Dynamický';
    }

    return result;
  }

  getKoeficientReducement() {
    let result = 0;
    const answers = this._answers;

    if (answers[24][2]) {
      result = 0.1;
    } else if (answers[24][3]) {
      result = 0.2;
    } else if (answers[24][4]) {
      result = 0.4;
    }

    return result;
  }

  calculateInvestmentLimit() {
    const investmentPercentage = this._investmentPercentage;
    const koeficientReducement = this.getKoeficientReducement();
    let koeficient;

    if (investmentPercentage <= 25) {
      koeficient = 1 - koeficientReducement;
    } else if (investmentPercentage > 25 && investmentPercentage <= 35) {
      koeficient = 0.9 - koeficientReducement;
    } else if (investmentPercentage > 35 && investmentPercentage <= 45) {
      koeficient = 0.8 - koeficientReducement;
    } else if (investmentPercentage > 45 && investmentPercentage <= 55) {
      koeficient = 0.7 - koeficientReducement;
    } else if (investmentPercentage > 55 && investmentPercentage <= 65) {
      koeficient = 0.6 - koeficientReducement;
    } else if (investmentPercentage > 65 && investmentPercentage <= 70) {
      koeficient = 0.5 - koeficientReducement;
    } else {
      koeficient = 0.4 - koeficientReducement;
    }

    return this._investment * koeficient;
  }

  calculateInvestmentGoals() {
    const answers = this._answers;
    let result = 'premenlivý výnos';

    if (answers[27][1]) {
      result = 'fixný výnos';
    } else if (answers[27][3]) {
      result = 'dividendu';
    }

    return result;
  }

  calculateInvestmentHorizon() {
    const answers = this._answers;
    let result = 'Presiahnutý vek';

    if (answers[26][1]) {
      result = 'Krátkodobý do 5 rokov (vrátane)';
    } else if (answers[26][2]) {
      result = 'Strednodobý nad 5 rokov do 10 rokov (vrátane)';
    } else if (answers[26][3]) {
      result = 'Dlhodobý viac ako 10 rokov';
    }

    return result;
  }

  calculateTeoreticalVolumeOfAssets() {
    const answers = this._answers;
    const amount = Math.round((this._investment / this._investmentPercentage) * 100);
    let result = 'OK';

    if (
      (answers[20][1] && amount > 100000) ||
      (answers[20][2] && (amount < 100000 || amount > 500000)) ||
      (answers[20][3] && amount < 500000)
    ) {
      result = amount + ' EUR - nesúlad s ot. 20';
    }

    return result;
  }

  evaluate() {
    const teoreticalVolumeOfAssets = this.calculateTeoreticalVolumeOfAssets();

    return [
      {
        id: 'FinancialSituation',
        label: 'C) Riziko celkovej straty investovaných prostriedkov klient',
        result: this._answers[23][1] ? 'Áno' : 'Nie'
      },
      {
        id: 'TeoreticalVolumeOfAssets',
        result: teoreticalVolumeOfAssets
      },
      {
        id: 'MaxInvestment',
        result: !this._answers[25][1]
          ? teoreticalVolumeOfAssets === 'OK'
            ? this.calculateInvestmentLimit() + ' Eur'
            : 'Nesprávne uvedený objem aktív ot. 20'
          : '0 Eur (Dôvod: Odpoveď 25a)'
      },
      {
        id: 'InvestmentLimitRetirement',
        label: 'Vek',
        result: this._answers[16][3]
      },
      {
        id: 'Risk',
        label: 'D) Informácie o prístupe klienta k rizikám',
        result: this.calculateRiskApproach()
      },
      {
        id: 'InvestmentHorizon',
        label: 'Investičný horizont klienta je',
        result: this.calculateInvestmentHorizon()
      },
      {
        id: 'InvestmentGoal',
        label: 'E) Informácie o investičných cieľoch a potrebách klienta',
        result: this.calculateInvestmentGoals()
      }
    ];
  }
}
